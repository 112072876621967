import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthUser } from 'react-auth-kit'
import Select from 'react-select'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import Papa from 'papaparse'

const ExpandedRow = ({ data }) => {
  return (
    <div className='p-4 col-md-4' style={{ fontSize: '14px' }}>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Reference: </span>
        <span>{data.pickup_code}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Partner: </span>
        <span>{data.partner_name}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Sender: </span>
        <span>{data.sender_name}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Country: </span>
        <span>{data.country}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Amount: </span>
        <span>{data.amount}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Status: </span>
        <span>{data.status}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Beneficiary: </span>
        <span>{data.receiver_name}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Total Amount: </span>
        <span>{data.sending_total_amount}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Receiving Option: </span>
        <span>{data.receiving_option}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Date: </span>
        <span>{data.created_at}</span>
      </div>
    </div>
  )
}

const SendMoney = () => {
  const { t } = useTranslation()
  const authUser = useAuthUser()

  const [status, setStatus] = useState([])
  const [source, setSource] = useState([])
  const [duration, setDuration] = useState('1')
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [dateError, setDateError] = useState(null)

  const transactionStatuses = [
    { value: 1, label: t('PENDING') },
    { value: 2, label: t('IN_PROGRESS') },
    { value: 3, label: t('PAID') },
    { value: 4, label: t('REJECTED') },
    { value: 5, label: t('CANCELLED') },
    { value: 6, label: t('TRANSMITTED') },
    { value: 7, label: t('FAILED') },
    { value: 8, label: t('CREATED') },
    { value: 9, label: t('ON_HOLD') },
    { value: 10, label: t('UNDER_REVIEW') },
    { value: 11, label: t('EXPIRED') },
    { value: 12, label: t('REJECTED_BY_COMPLIANCE') },
  ]

  const transactionSources = [
    { value: 'posapp', label: 'POSAPP' },
    { value: 'cashapp', label: 'CASHAPP' },
    { value: 'agentportal', label: 'AGENTPORTAL' },
  ]

  const transactionDurations = [
    { value: '1', label: t('TODAY') },
    { value: '2', label: t('THIS_WEEK') },
    { value: '3', label: t('THIS_MONTH') },
    { value: '4', label: t('PREVIOUS_WEEK') },
    { value: '5', label: t('PREVIOUS_MONTH') },
    { value: '6', label: t('CUSTOM_DATE') },
  ]

  const getCurrentDate = () => {
    const today = new Date()
    return today.toISOString().split('T')[0]
  }

  const handleFromDateChange = (e) => {
    const selectedDate = e.target.value
    setFromDate(selectedDate)

    if (!toDate) {
      const fromDateObject = new Date(selectedDate)
      const autoToDateObject = new Date(
        fromDateObject.getFullYear(),
        fromDateObject.getMonth(),
        fromDateObject.getDate() + 30
      )
      const autoToDate = autoToDateObject.toISOString().split('T')[0]
      setToDate(autoToDate)
    }

    // If endDate is earlier than startDate, align it with startDate
    if (toDate && new Date(selectedDate) > new Date(toDate)) {
      setToDate(selectedDate)
    }
  }

  const handleToDateChange = (e) => {
    const selectedDate = e.target.value

    // Ensure end_date is not earlier than start_date
    if (fromDate && new Date(selectedDate) < new Date(fromDate)) {
      setDateError(t('END_DATE_BEFORE_START_DATE'))
    } else {
      setDateError(null)
      setToDate(selectedDate)
    }
  }

  const fetchData = async () => {
    if (duration === '6' && (!fromDate || !toDate)) {
      return
    }

    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/partner/transactions`,
        {
          is_sent: 1,
          status: status.map((s) => s.value),
          source: source.map((s) => s.value),
          duration,
          fromDate: duration === '6' ? fromDate : undefined,
          toDate: duration === '6' ? toDate : undefined,
        },
        {
          headers: {
            Authorization: `${authUser().tokenType} ${authUser().token}`,
          },
        }
      )
      setData(response.data.data) // Adjust based on your API's response format
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const defineColumns = () => {
    const windowWidth = window.innerWidth

    const allColumns = [
      {
        name: '#',
        selector: (row) => row.DT_RowIndex,
        sortable: true,
        width: '60px',
      },
      {
        name: 'Reference',
        selector: (row) => row.pickup_code,
        cell: (row) => (
          <a href={`/send-money/${row.pickup_code}`}>{row.pickup_code}</a>
        ),
        width: '180px',
      },
      {
        name: 'Partner',
        selector: (row) => row.partner_name,
        hideOnMobile: true,
        sortable: true,
        width: '100px',
      },
      {
        name: 'Sender',
        selector: (row) => row.sender_name,
        hideOnMobile: true,
        sortable: true,
        width: '160px',
      },
      {
        name: 'Country',
        selector: (row) => row.country,
        width: '100px',
      },
      {
        name: 'Amount',
        selector: (row) => row.amount,
      },
      {
        name: 'Status',
        selector: (row) => row.status,
        hideOnMobile: true,
        width: '150px',
      },
      {
        name: 'Beneficiary',
        selector: (row) => row.receiver_name,
        hideOnMobile: true,
        sortable: true,
        width: '180px',
      },
      {
        name: 'Total Amount',
        selector: (row) => row.sending_total_amount,
        hideOnMobile: true,
        width: '150px',
      },
    ]

    // Filter columns based on screen width
    const filteredColumns = allColumns.filter(
      (column) => !(column.hideOnMobile && windowWidth < 768)
    )

    setColumns(filteredColumns)
  }

  const exportCSV = () => {
    if (data.length === 0) {
      alert(t('NO_DATA_TO_EXPORT'))
      return
    }

    const formattedData = data.map((row) => ({
      Reference: row.pickup_code,
      Partner: row.partner_name,
      Sender: row.sender_name,
      Country: row.country,
      Amount: row.amount,
      Status: row.status,
      Beneficiary: row.receiver_name,
      'Total Amount': row.sending_total_amount,
      'Receiving Option': row.receiving_option,
      Date: row.created_at,
    }))

    const csv = Papa.unparse(formattedData, {
      header: true,
    })

    const now = new Date()
    const timestamp = now
      .toISOString()
      .replace(/[-T:.Z]/g, '')
      .slice(0, 14)
    const fileName = `transactions_${timestamp}.csv`

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    fetchData()
    defineColumns()
    window.addEventListener('resize', defineColumns)

    return () => {
      window.removeEventListener('resize', defineColumns)
    }
  }, [status, source, duration, fromDate, toDate])

  return (
    <div style={{ padding: '20px' }}>
      <div className='card'>
        <div className='card-header'>
          <h5>{t('SENT_TRANSACTIONS')}</h5>
        </div>
        <div className='card-body'>
          <form className='mb-5'>
            <div className='form-group row justify-content-md-center'>
              <div className='col-md-4'>
                <label htmlFor='status'>{t('STATUS')}</label>
                <Select
                  options={transactionStatuses}
                  closeMenuOnSelect={false}
                  placeholder={t('ALL_STATUSES')}
                  isMulti
                  onChange={setStatus}
                />
              </div>
              <div className='col-md-4'>
                <label htmlFor='source'>{t('SOURCE')}</label>
                <Select
                  options={transactionSources}
                  closeMenuOnSelect={false}
                  isMulti
                  placeholder={t('ALL_SOURCES')}
                  onChange={setSource}
                />
              </div>
              <div className='col-md-4'>
                <label htmlFor='duration'>{t('DURATION')}</label>
                <select
                  className='form-select'
                  name='duration'
                  id='duration'
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                >
                  {transactionDurations.map((d, index) => (
                    <option key={index} value={d.value}>
                      {d.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {duration === '6' && (
              <div className='form-group row justify-content-md-center mt-4'>
                <div className='col-md-3'>
                  <label htmlFor='from_date'>{t('FROM_DATE')}</label>
                  <input
                    className='form-control'
                    type='date'
                    id='from_date'
                    value={fromDate || ''}
                    onChange={handleFromDateChange}
                    max={getCurrentDate()}
                  />
                </div>
                <div className='col-md-3'>
                  <label htmlFor='to_date'>{t('TO_DATE')}</label>
                  <input
                    className='form-control'
                    type='date'
                    id='to_date'
                    value={toDate || ''}
                    onChange={handleToDateChange}
                    max={getCurrentDate()}
                  />
                  {dateError && (
                    <span className='text-danger' style={{ fontSize: '12px' }}>
                      {dateError}
                    </span>
                  )}
                </div>
              </div>
            )}
          </form>

          {data.length > 0 && (
            <button
              className='btn btn-primary tw-float-right mb-3'
              onClick={exportCSV}
              style={{ fontSize: '14px' }}
            >
              {t('EXPORT_AS_CSV')}
            </button>
          )}

          <DataTable
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            expandableRows
            expandableRowsComponent={ExpandedRow}
          />
        </div>
      </div>
    </div>
  )
}

export default SendMoney
