import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import RiaFees from './RiaFees';
import { setCodeTable, setComplete, setSEND_MONEY_OBJECT, setStep } from '../../../slices/send-money-ria-slice';
import { useAuthUser } from 'react-auth-kit';
import { useLocation, useNavigate, useHistory } from 'react-router-dom';
import $ from 'jquery';
import RiaSender from './RiaSender';
import RiaReceiver from './RiaReceiver';
import RiaSummary from './RiaSummary';
import '../../MoneyGram/Receive/stepper.css';
import { TiTick } from "react-icons/ti";
import RiaComplete from './RiaComplete';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useImmer } from 'use-immer';

function RiaHome() {

    const authUser = useAuthUser()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const SEND_MONEY_OBJECT = useSelector((state) => state.sendMoneyRia.SEND_MONEY_OBJECT);
    const [sendMoneyObject, setSendMoneyObject] = useImmer(SEND_MONEY_OBJECT);
    const currentStep = useSelector(state => state.sendMoneyRia.step);
    const complete = useSelector(state => state.sendMoneyRia.complete);
    const steps = [t("QUOTATION"), t("SENDER"), t("RECEIVER"), t("SUMMARY")];

    useEffect(() => {
        dispatch(setSEND_MONEY_OBJECT(sendMoneyObject))
        console.log('new object', sendMoneyObject)
    }, [sendMoneyObject]);

    useEffect(() => {
        // dispatch(setStep(1))
        dispatch(setComplete(false))
        setSendMoneyObject({})

        let params = {
            language: i18next.language,
            // sendingCountry: "SL"
            sendingCountry: globalAuthUser?.location?.agent_country?.sortname
        }

        axios.post(
            process.env.REACT_APP_PARTNER_MODULE_BASE_URL + '/api/ria/codeTable', params, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': process.env.REACT_APP_PARTNER_MODULE_USER_TOKEN
            }
        }).then(res => {
            console.log('res');
            console.log(res.data.data);
            dispatch(setCodeTable(res.data.data));

            let obje = {
                codeTable : res.data.data,
                step : 1
            }
            setSendMoneyObject(obje)

            // setTimeout(() => {
            //     // setSEND_MONEY_OBJECT({ ...SEND_MONEY_OBJECT, step: 1})
            //     dispatch(setSEND_MONEY_OBJECT({ ...SEND_MONEY_OBJECT, step: 1}))
            //   }, 1200);
            
        }).catch(err => {
            console.log("error in code table");
            console.log(err);
        });
    }, []);

    return (
        <div style={{ padding: "20px" }}>
            <div className="card">
                <div className="card-header" style={{ fontWeight: "600" }}>
                    {t("RIA_SEND_TRANSACTION")}
                </div>
                <div className="card-body">
                    <div className="tw-flex tw-justify-between">
                        {steps?.map((step, i) => (
                            <div
                                key={i}
                                className={`step-item ${SEND_MONEY_OBJECT.step === i + 1 && "active"} ${(i + 1 < SEND_MONEY_OBJECT.step || complete) && "complete"
                                    } `}
                            >
                                <div className="step">
                                    {i + 1 < SEND_MONEY_OBJECT.step || complete ? <TiTick size={24} /> : i + 1}
                                </div>
                                <p className="tw-text-gray-500">{step}</p>
                            </div>
                        ))}
                    </div>

                    <hr />

                    {SEND_MONEY_OBJECT.step == 1 &&  <RiaFees /> }
                    {SEND_MONEY_OBJECT.step == 2 &&  <RiaSender /> }
                    {SEND_MONEY_OBJECT.step == 3 &&  <RiaReceiver /> }
                    {SEND_MONEY_OBJECT.step == 4 &&  <RiaSummary /> }
 
                </div>
            </div>
        </div>
    );

}

export default RiaHome