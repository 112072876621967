import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { hideLoading, showLoading } from '../../../slices/loading-slice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthUser } from 'react-auth-kit'
import { showToast } from '../../../slices/toast-slice'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmptyObject } from 'jquery'
import { FaArrowLeft } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { formatAmount } from '../../../utils/helper'
import GlobalConfirmPasscod from '../../GlobalConfirmPasscod'

const CreateCashout = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const authUser = useAuthUser()
  const globalAuthUser = useSelector((state) => state.auth.globalAuthUser)
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [params, setParams] = useState({
    cashoutAmount: 0,
    agentPhoneNumber: '',
    code: '',
  })
  const [error, setError] = useState(null)
  const [customer, setCustomer] = useState([])
  const [fees, setFees] = useState([])
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(0)
  const [minError, setminError] = useState('')
  const [maxError, setmaxError] = useState('')
  const [txnFees, setTxnFees] = useState('')
  const [txnTotal, setTxnTotal] = useState('')
  const [otpSent, setOtpSent] = useState(false)
  const [countdown, setCountdown] = useState('1:00')
  const [displayResend, setdisplayResend] = useState(false)
  const [authorize, setAuthorize] = useState(false)
  const [submit, setSubmit] = useState(false)

  function translate(text) {
    let trans = t(text)
    console.log('trans', trans)
    if (trans != null) {
      return text
    } else return trans
  }

  useEffect(() => {
    dispatch(showLoading())
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    }
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getInfoForCashout',
        {},
        { headers }
      )
      .then((res) => {
        dispatch(hideLoading())
        if (res.data.status == 1) {
          setFees(res.data.data.fees)
        }
      })
      .catch((error) => {
        dispatch(hideLoading())
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        )
      })
  }, [])
  useEffect(() => {
    if (fees && fees.length !== 0) {
      const minAmounts = fees.map((item) => item.minimum_amount)
      const maxAmounts = fees.map((item) => item.maximum_amount)
      const min = Math.min(...minAmounts)
      const max = Math.max(...maxAmounts)
      setMin(min)
      setMax(max)
    }
  }, [fees])
  const onClose = () => {
    navigate('/mobile-money')
  }
  const handleInput = (e) => {
    e.persist()
    if (e.target.name == 'code' && e.target.value != null) {
      setSubmit(true)
    }
    setParams((prevParams) => {
      const updateParams = { ...prevParams, [e.target.name]: e.target.value }
      return updateParams
    })
  }
  function saveCicoRequest(event) {
    event.preventDefault()
    if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
      openModal()
      return
    }
    verifyOTP()
  }
  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }
  const doCashout = () => {
    setSubmit(false)
    setIsModalOpen(false)
    dispatch(showLoading())
    const payload = {
      user_id: customer?.id,
      amount: params.cashoutAmount,
      fees: txnFees,
      otp: params.code,
    }
    console.log(payload)
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    }
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/saveCashout',
        payload,
        { headers }
      )
      .then((res) => {
        dispatch(hideLoading())
        if (res.data.status === 1) {
          setSubmit(false)
          const btn = document.getElementsByClassName('btn')[0]
          // Navigate to a new page after a delay
          btn.setAttribute('disabled', 'disabled')
          dispatch(
            showToast(['success', t('INFORMATION'), t(res.data.message)])
          )
          setTimeout(() => {
            navigate('/mobile-money')
          }, 1000)
        } else {
          setSubmit(true)
          dispatch(showToast(['danger', t('INFORMATION'), t(res.data.message)]))
        }
      })
      .catch((error) => {
        dispatch(hideLoading())
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        )
        setSubmit(true)
      })
  }
  const searchCustomer = () => {
    dispatch(showLoading())
    var payload = {
      searchParam: params.agentPhoneNumber,
      agent_country_id: globalAuthUser.location.country_id,
    }
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    }
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/searchCustomer',
        payload,
        { headers }
      )
      .then((res) => {
        if (res && res.data.status == 0) {
          setError(res.data.message)
        } else {
          setError('')
        }
        dispatch(hideLoading())
        setCustomer(res.data.data)
      })
      .catch((error) => {
        dispatch(hideLoading())
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        )
      })
  }
  const handleInputChange = (e) => {
    e.persist()
    setParams((prevParams) => {
      const updateParams = { ...prevParams, [e.target.name]: e.target.value }
      return updateParams
    })
    const inputValue = e.target.value
    let calculatedFee = ''
    let calculatedTotal = ''
    if (inputValue !== '' && !isNaN(inputValue) && inputValue !== '0') {
      for (const f of fees) {
        if (f.minimum_amount <= inputValue && f.maximum_amount >= inputValue) {
          if (f.charges_type == 1) {
            calculatedFee = f.charges
          } else {
            calculatedFee = (inputValue * f.charges) / 100
          }
          break
        }
      }
      if (inputValue <= min) {
        // resetAlert();
        setminError(`Minimum Amount is ${min}`)
        setAuthorize(false) // Hide Authorize button
        setdisplayResend(false)
        setSubmit(false)
      } else if (inputValue > max) {
        setminError(`Maximum Amount is ${max}`)
        setAuthorize(false) // Hide Authorize button
        setdisplayResend(false)
        setSubmit(false)
      } else {
        setminError('')
        setmaxError('')
        setAuthorize(true)
      }
    } else {
    }
    // setAmount(inputValue);
    setTxnFees(calculatedFee)
    // $("#co_fees").val(calculatedFee);
    calculatedTotal =
      !isNaN(inputValue) && !isNaN(calculatedFee)
        ? +inputValue + calculatedFee
        : ''
    setTxnTotal(calculatedTotal)
    // checkSubmit();
  }
  const requestOTP = () => {
    dispatch(showLoading())
    var payload = {
      user_id: customer?.id,
      amount: params.cashoutAmount,
    }
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    }

    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL +
          '/api/portal/getUserBalanceForCashout',
        payload,
        { headers }
      )
      .then((res) => {
        console.log(res.data)
        if (res && res.data.data.balancies) {
          if (res.data.data.balancies[0].balance < txnTotal) {
            dispatch(hideLoading())
            dispatch(
              showToast(['danger', t('INFORMATION'), t('INSUFFICIENT_BALANCE')])
            )
          } else {
            axios
              .post(
                process.env.REACT_APP_BNB_BASE_URL +
                  '/api/portal/sendOtPForCashout',
                payload,
                { headers }
              )
              .then((res) => {
                if (res && res.data.status == 1) {
                  dispatch(
                    showToast([
                      'success',
                      t('INFORMATION'),
                      t(res.data.message),
                    ])
                  )
                  setTimeout(() => {
                    // Simulate successful response
                    setOtpSent(true)
                    countdownFunction()
                    setAuthorize(false) // Hide Authorize button
                    setdisplayResend(false)
                  }, 1000)
                } else {
                  //   setError(res.data.message)
                  dispatch(
                    showToast(['danger', t('INFORMATION'), t(res.data.message)])
                  )
                }
                dispatch(hideLoading())
              })
              .catch((error) => {
                dispatch(hideLoading())
                dispatch(
                  showToast([
                    'danger',
                    t('SERVER_ERROR'),
                    t('ERROR_HAS_OCCURED'),
                  ])
                )
              })
          }
        }
      })
      .catch((error) => {
        dispatch(hideLoading())
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        )
      })
  }
  const verifyOTP = () => {
    dispatch(showLoading())
    var payload = {
      user_id: customer?.id,
      code: params.code,
    }
    const headers = {
      Authorization: authUser().tokenType + ' ' + authUser().token,
    }
    axios
      .post(
        process.env.REACT_APP_BNB_BASE_URL + '/api/portal/verifyOtpForCashout',
        payload,
        { headers }
      )
      .then((res) => {
        setTimeout(() => {
          // Simulate successful response
          setOtpSent(true)
          countdownFunction()
        }, 1000)
        if (res && res.data.status === 0) {
          // setError(res.data.message)
          dispatch(showToast(['danger', t('Invalid OTP'), t(res.data.message)]))
        }
        if (res && res.data.status === 1) {
          doCashout()
        }
        dispatch(hideLoading())
      })
      .catch((error) => {
        dispatch(hideLoading())
        dispatch(
          showToast(['danger', t('SERVER_ERROR'), t('ERROR_HAS_OCCURED')])
        )
      })
  }
  const countdownFunction = () => {
    let timer = '1:00'
    const interval = setInterval(() => {
      let [minutes, seconds] = timer.split(':').map(Number)
      seconds--
      if (seconds < 0) {
        minutes--
        seconds = 59
      }
      if (minutes < 0) {
        clearInterval(interval)
        // Timer expired
        // setOtpSent(false);
        setdisplayResend(true)
        return
      }
      seconds = seconds < 10 ? `0${seconds}` : seconds
      setCountdown(`${minutes}:${seconds}`)
      timer = `${minutes}:${seconds}`
    }, 1000)
  }

  return (
    <>
      <React.Fragment>
        <div style={{ padding: '20px' }}>
          <div className='card'>
            <div className='card-header d-flex justify-content-between'>
              <h5 className='col-sm-4'>{t('BNB_CASHAPP_CASHOUT')}</h5>
              <span>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => navigate('/mobile-money')}
                >
                  <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                  {t('BACK')}
                </button>
              </span>
            </div>

            <div className='card-body'>
              <form id='my-form' onSubmit={saveCicoRequest}>
                <div className='form-group row mb-3'>
                  <div className='col-md-6'>
                    <label className='' htmlFor='agentPhoneNumber'>
                      {t('SEARCH_BY_PHONE_NUMBER')}
                      <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='number'
                      name='agentPhoneNumber'
                      id='agentPhoneNumber'
                      onChange={handleInput}
                      value={params.agentPhoneNumber}
                      className={`form-control custom-field mb-1`}
                      required
                    />
                    {error && <span className='text-danger'>{error}</span>}
                    {/* <span>{t('INCLUDE_COUNTRY_CODE')}</span> */}
                  </div>
                </div>
                <div className='form-group row mb-3 '>
                  <div className='col-md-6'>
                    <button
                      type='button'
                      className='col-sm-2 btn btn-primary'
                      id='apply-filter-paid'
                      data-count='0'
                      onClick={searchCustomer}
                    >
                      {t('SEARCH')}
                    </button>
                  </div>
                </div>

                {customer?.id && (
                  <>
                    <div className='row mb-3'>
                      <p>
                        {t('CUSTOMER_NAME')} :{customer?.first_name}{' '}
                        {customer?.last_name}
                      </p>
                    </div>

                    <div className='form-group row'>
                      <div className='col-md-6 mb-3'>
                        <label className='' htmlFor='cashoutAmount'>
                          {t('AMOUNT')} ({globalAuthUser?.location.currency})
                          <span className='text-danger'>*</span>
                        </label>
                        <input
                          type='number'
                          name='cashoutAmount'
                          id='cashoutAmount'
                          onChange={handleInputChange}
                          className='form-control custom-field'
                          required
                        />
                        {minError && (
                          <span className='text-danger'>{minError}</span>
                        )}
                        <span className='text-danger'>{maxError}</span>
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className='col-md-3 '>
                        {t('FEES')}:{txnFees}
                      </div>
                      <div className='col-md-3 mt-2'>
                        {t('TOTAL_AMOUNT')}:{txnTotal}
                      </div>
                    </div>

                    {authorize && (
                      <button
                        type='button'
                        className='btn btn-primary mt-2'
                        id='apply-filter-paid'
                        data-count='0'
                        onClick={requestOTP}
                      >
                        Authorize Transaction
                      </button>
                    )}

                    {displayResend && (
                      <button
                        type='button'
                        className='btn btn-primary btn-md btn-resend-otp mt-2 mb-4'
                        onClick={requestOTP}
                      >
                        {t('RESEND')}
                      </button>
                    )}

                    <span
                      className='countdown fw-600 mb-2'
                      style={{ display: otpSent ? 'block' : 'none' }}
                    >
                      {countdown}
                    </span>

                    <div
                      id='otpDiv'
                      className='form-group col-md-8'
                      style={{ display: otpSent ? 'block' : 'none' }}
                    >
                      <b>
                        <span className='fw-600'>{t('ENTER_OTP_CODE')}</span>
                      </b>
                      <br />
                      <label htmlFor='code'>
                        {t('ENTER_OTP_CODE')}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        type='text'
                        name='code'
                        placeholder=''
                        id='code'
                        className='form-control'
                        onChange={handleInput}
                      />
                    </div>

                    {/* <button onClick={verifyOTP}>Verify Otp</button> */}

                    <div className='form-group row justify-content-md-center mt-2'>
                      <button
                        type='submit'
                        className='col-sm-2 btn btn-primary'
                        id='apply-filter-paid'
                        data-count='0'
                        disabled={
                          !submit || minError || maxError ? 'disabled' : ''
                        }
                      >
                        {t('SUBMIT')}
                      </button>
                      &nbsp;
                      <button
                        type='button'
                        className='col-sm-2 btn btn-secondary'
                        id='apply-filter-clear-paid'
                        onClick={onClose}
                      >
                        {t('CANCEL')}
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
        <div>
          <GlobalConfirmPasscod
            isOpen={isModalOpen}
            onClose={closeModal}
            callParentFunction={doCashout}
          />
        </div>
      </React.Fragment>
    </>
  )
}

export default CreateCashout
